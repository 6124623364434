/**
 * DOMAINS ENVIRONMENT VARIABLE
 */

import { nextRouteTypes } from '../model/component/SummaryModel'
import mapStoreAffiliated from './../../assets/images/svg/map_indicator_grey.svg'
import mapStoreOfficial from './../../assets/images/svg/map_indicator_orange.svg'

export const SHOPPING_CART_ITEMKEY_STORAGE: string = 'phs-shopping-cart-consumer'
export const SHOPPING_CART_GEOGRAPHICS_ITEMKEY_STORAGE: string = 'phs-shopping-cart-consumer-geographics'
export const X_API_KEY: string = `${process.env.REACT_APP_X_API_KEY}`
export const XHR_TIMEOUT: number = (Number(process.env.REACT_APP_XHR_TIMEOUT) || 0) as number
export const XHR_RETRY: number = (Number(process.env.REACT_APP_XHR_RETRY) || 0) as number
export const GOOGLEMAPS_CUSTOM_SHOP_ICON = mapStoreOfficial
export const GOOGLEMAPS_CUSTOM_SHOP_ICON_AFFILIATED_SHOP = mapStoreAffiliated
export const GOOGLEMAPS_MARKER_CARD_DISTANCE_DEFUALT_UNIT: string = 'm'
export const PATH_CONSUMER_ORDER_PANEL: string = process.env.REACT_APP_PATH_CONSUMER_ORDER_PANEL || ''
export const PATH_CONSUMER_PRINT_OTHER_EMOTIONS: string = process.env.REACT_APP_PATH_CONSUMER_PRINT_OTHER_EMOTIONS || ''
export const LOGIN_CONSUMER: string = process.env.REACT_APP_LOGIN_CONSUMER || ''
export const EDITOR_RETURN_FROM_CRASHPAGE: string = process.env.REACT_APP_EDITOR_RETURN_FROM_CRASHPAGE || ''
export const PATH_DOWNLOAD_APP: string = process.env.REACT_APP_PATH_DOWNLOAD_APP || ''

export const SHIPPING_METHOD_PICKUP: acceptedShippingMethodNames = 'PickUpStore'
export const SHIPPING_METHOD_MAILORDER: acceptedShippingMethodNames = 'MailOrder'
export const SHIPPING_METHOD_EXPRESS: acceptedShippingMethodNames = 'Express'
export type acceptedShippingMethodNames = 'PickUpStore' | 'MailOrder' | 'Express' | string
export const acceptedShippingMethod = [`${SHIPPING_METHOD_PICKUP}`, `${SHIPPING_METHOD_MAILORDER}`, `${SHIPPING_METHOD_EXPRESS}`]

export const OPENED_CLASS: string = 'opened'
export const defaultMapsHeight: string = '500px'

export const RETAILER_USER: userTypes = 'RetailerUser'
export const CONSUMER_USER: userTypes = 'ConsumerUser'
export const PROFESSIONAL_USER: userTypes = 'ProfessionalUser'
export type userTypes = 'ConsumerUser' | 'RetailerUser' | 'ProfessionalUser'
export const acceptedUserTypes: Array<userTypes> = [RETAILER_USER, CONSUMER_USER]

export const PAYMENT_ONLINE: acceptedPaymentType = (process.env.REACT_APP_PAYMENT_ONLINE || 'ONLINE') as acceptedPaymentType
export const PAYMENT_SHOP: acceptedPaymentType = (process.env.REACT_APP_PAYMENT_SHOP || 'SHOP') as acceptedPaymentType
export const PAYMENT_CASH_ON_DELIVERY: acceptedPaymentType = (process.env.REACT_APP_PAYMENT_CASH_ON_DELIVERY ||
  'CASHONDELIVERY') as acceptedPaymentType
export type acceptedPaymentType = 'ONLINE' | 'SHOP' | 'CASHONDELIVERY' | 'INSHOP'

export const LAST_GUID_STORAGE_KEY: string = 'last_guid'

export const PAYMENT: nextRouteTypes = 'payment'
export const SHIPPING: nextRouteTypes = 'shipping'
export const SUMMARY: nextRouteTypes = 'summary'

export const SHIPPING_METHODS_STORAGE_KEY: string = 'shipping_method_list'

export const SHARE_WITH_FIRENDS_CAMPAIGN_CODE: string = process.env.REACT_APP_SHARE_WITH_FIRENDS_CAMPAIGN_CODE || ''

export const STATUS_OK: string = 'ok'
export const STATUS_KO: string = 'ko'

export const GIFTBOX: acceptedBadge = 'giftbox'
export type acceptedBadge = 'giftbox' | null

export const GOOGLE_MAPS_API_KEY: string = process.env.REACT_APP_GOOGLE_API_KEY || ''

export const COUPON_MAX_LENGTH: number = Number(process.env.REACT_APP_COUPON_MAX_LENGTH) || 50

export const ACCEPT_CONDITION_PAGE: string = 'https://static.photosi.com/cdn/docs/{lang}/terms_conditions.html'
export const ACCEPTED_CONDITION_STORAGE_KEY: string = 'accptedConditionBeforePayment'

export const PRODUCT_QTY_MAX: number = Number(process.env.REACT_APP_PRODUCT_QTY_MAX) || 999
export const PRODUCT_QTY_MIN: number = Number(process.env.REACT_APP_PRODUCT_QTY_MIN) || 1

export const SHOW_ORDER_NUMBER: number = Number(process.env.REACT_APP_SHOW_ORDER_NUMBER) || 0

export const INPUT_FORM_DEFAULT_MAXLENGTH: number = 30

export const DATADOG_X_API_KEY = 'fee966f56d3dfdc74558fe794ce8c0a5'
export const DATADOG_APP_KEY = process.env.REACT_APP_DATADOG_APP_KEY || ''
export const DEFAULT_DISTRIBUTOR_CODE = process.env.REACT_APP_DEFAULT_DISTRIBUTOR_CODE || ''

export const SOFTWARE_CODE = process.env.REACT_APP_SOFTWARE_CODE || ''
export const SOFTWARE_CODE_SERVICES = process.env.REACT_APP_SOFTWARE_CODE_SERVICES || ''
export const SOFTWARE_VERSION = process.env.REACT_APP_SOFTWARE_VERSION || ''

export const DEFAULT_COUPON_PROMO_CODE = '10PROMO'
export const DENIED_COUPON_PROMO_CODE = '10PROMO'

export const ACCEPTED_QUERY_STRING_KEY = [
  'guid',
  'token',
  'status',
  'message',
  'nextUrl',
  'softwareCode',
  'softwareVersion',
  'productsAdded'
]
export const NAVIGATOR_DENIED_QUERY_STRING_KEY = ['token', 'softwareCode', 'softwareVersion', 'productsAdded']
export const PAGE_TYPE_SHOPPING_CART = 'shopping_cart'
export const PAGE_TYPE_SHOPPING_CART_ERROR = 'shopping_cart_error'

// ADDRESSES FORM INPUT MAX LENGTH
export const ZIPCODE_MAX_LENGTH = 9
export const CIVIC_NUMBER_MAXLENGTH = 10
export const CITY_MAX_LENGTH = 30
export const PHONE_MAX_LENGTH = 15
export const LASTNAME_MAX_LENGTH = 20
export const FIRSTNAME_MAX_LENGTH = 20
export const CAREOF_MAX_LENGHT = 25
export const ADDITIONAL_INFO_MAX_LENGHT = 30

// filter countries in addreess chooser, added to provent empty areas in RSM and Vaticano.
const acceptedCountries: string = process.env.REACT_APP_ACCEPTED_ADDRESSED_COUNTRIES || ''
export const ACCEPTED_ADDRESSED_COUNTRIES: string[] = acceptedCountries.length > 0 ? acceptedCountries.split(',') : []
export const LAST_PAYMENT_VENDOR_KEY = 'phs-last_payment_vendor'
export const SOFTWARECODE_KEY = 'phs-softwareCode'
export const SOFTWARE_VERSION_KEY = 'phs-softwareVersion'

export const VERSION_WEB = '1.00'

export const APP_PATH_EDITOR = process.env.REACT_APP_PATH_EDITOR || ''

export const PHOTOSI_EDITOR_LABEL = 'photosi-editor'
export const IPLABS_EDITOR_LABEL = 'iplabs-editor'

export const ORDER_TYPE = 'order_type'
export const PLATFORM_TYPE = 'platform_type'
export const DEBOUNCE_TIMEOUT: number = 700

export const ADDRESS_TYPE_SHIPPING = 'shipping'
export const DATADOG_APPLICATION_ID = process.env.REACT_APP_DATADOG_APPLICATION_ID
export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN
