import { CountryApiResult } from '../model/GeographicsInterface'
import { CountryStoreInterface } from '../state-manager/CountryStore'

export const CountriesAdapter = (countries: CountryApiResult[]): CountryStoreInterface[] => {
  return countries.map((country: CountryApiResult) => CountryAdapter(country))
}

export const CountryAdapter = (country: CountryApiResult): CountryStoreInterface => {
  return Object.assign(
    {},
    {
      iso: country.iso,
      isoAlpha2: country.isoAlpha2,
      isoAlpha3: country.isoAlpha3,
      name: country.name,
      phonePrefix: country.phonePrefix,
      phoneRegex: country.phoneRegex
    }
  )
}
