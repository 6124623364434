import { getSnapshot } from 'mobx-state-tree'
import { concat, forkJoin, Observable, Observer, of, throwError } from 'rxjs'
import { flatMap, map, mergeMap, reduce, tap } from 'rxjs/operators'
import { getRetailerShopCodeFromCookie, getUniqueRetailerDataFromCookie } from '../../app/helpers/utils'
import { StorageWrapperInterface } from '../../app/service/storage/StorageWrapper'
import { ConsumerUserInfoApiAdapter } from '../adapter/ConsumerUserInfoApiAdapter'
import { ConsumerUserMarketingDataAdapter } from '../adapter/ConsumerUserMarketingDataAdapter'
import { OrderStoreAdapter } from '../adapter/OrderAdapter'
import { ProductsHeaderAdapter } from '../adapter/ProductsHeaderAdapter'
import { ProductsStoreAdapter } from '../adapter/ProductStoreAdapter'
import { RetailerUserInfoApiAdapter } from '../adapter/RetailerUserInfoApiAdapter'
import { ShippingMethodAdapter } from '../adapter/ShippingMethodAdapter'
import { ShopAdapterToStore, ShopManagerApiAdapter } from '../adapter/ShopAdapter'
import { HeaderApiAdapter } from '../adapter/ShoppingCartHeaderApiAdapter'
import { HeaderStoreAdapter } from '../adapter/ShoppingCartHeaderStoreAdapter'
import { UserTypesApiAdapter } from '../adapter/UserTypesApiAdapter'
import { FacadeApiClientInterface } from '../api-service/FacadeApiClient'
import { setShippingMethod } from '../components/shipping/choose-method/ChooseMethodHelpers'
import {
  acceptedPaymentType,
  acceptedShippingMethodNames,
  CONSUMER_USER,
  RETAILER_USER,
  SHIPPING_METHOD_EXPRESS,
  SHIPPING_METHOD_MAILORDER,
  SHIPPING_METHOD_PICKUP,
  SOFTWARECODE_KEY,
  SOFTWARE_CODE,
  SOFTWARE_CODE_SERVICES,
  userTypes
} from '../environment/const'
import { setUserInfo } from '../helpers/setUserInfo'
import OrderApiResult from '../model/api-result/OrderApiResult'
import { ProductsHeaderApiResultInterface } from '../model/api-result/ProductsHeaderApiResultInterface'
import { ShippingMethodApiResult } from '../model/api-result/ShippingMethodApiResult'
import { ShopManagerApiResultInterface } from '../model/api-result/ShopManagerApiResult'
import { ShoppingCartHeaderApiResultInterface } from '../model/api-result/ShoppingCartHeaderApiResultInterface'
import { PatchShippingAddress } from '../model/PatchShippingAddress'
import { UniqueRetailerDataCookieInteface } from '../model/RetailerDataCookieInterface'
import { ShippingMethodModel } from '../model/ShippingMethod'
import { ShopManagerInterface } from '../model/ShopManagerModel'
import { ShoppingCartStoreAcceptedStatusList } from '../model/ShoppingCartStoreAcceptedStatus'
import {
  ConsumerUserInfoStoreInterface,
  ConsumerUserInfoStoreInterfaceSnapshotIn,
  ConsumerUserInfoStoreInterfaceSnapshotOut
} from '../state-manager/ConsumerUserInfoStore'
import { ConsumerUserMarketingDataStoreInterfaceSnapshotOut } from '../state-manager/ConsumerUserMarketingDataStore'
import { OrderStoreInterfaceSnapshotOut } from '../state-manager/OrderStore'
import { ProductsHeaderStoreInterface } from '../state-manager/ProductsHeaderStore'
import { RetailerUserInfoStoreInterface, RetailerUserInfoStoreInterfaceSnapshotOut } from '../state-manager/RetailerUserInfoStore'
import { ShippingAddressStoreInterface } from '../state-manager/ShippingAddress'
import { ShippingMethodStoreInterfaceSnapshotOut } from '../state-manager/ShippingMethodStore'
import { ShopForPickUpInterfaceSnapshotOut } from '../state-manager/ShopForPickUp'
import { ShoppingCartHeaderStoreInterfaceSnapshotOut } from '../state-manager/ShoppingCartHeaderStore'
import { ShoppingCartStoreInterface } from '../state-manager/ShoppingCartStore'
import { UserTypesStoreInterfaceSnapshotOut } from '../state-manager/UserTypesStore'
import { AmplitudeEventInterface } from './AmplitudeEvent'
import { CouponManagerServiceInterface } from './CouponManager'
import { datadogService } from './DatadogService'
import { ShippingAddressInterface } from './ShippingAddress'

/**
 * @interface
 */
export interface ShoppingCartDataServiceInterface {
  getAndPersistHeadersAndProducts$(): Observable<any>

  putCartHeader$(): Observable<ShoppingCartHeaderApiResultInterface | Error>

  getAndPersistOrder$(): Observable<OrderStoreInterfaceSnapshotOut>

  patchCurrentShopDataForPickUpOrder$(): Observable<any>

  getAndPersistHeader$(): Observable<any>

  getAndPersistProducts$(): Observable<any>

  persistChoosedShippingAddress$(
    shippingType: acceptedShippingMethodNames,
    shopCode?: string,
    paymentType?: acceptedPaymentType
  ): Observable<any>

  originIsRetailerDataResolve$(): Observable<any>

  getAndPersistConsumerUserInfo$(): Observable<ConsumerUserInfoStoreInterface>

  getAndPersistConsumerUserMarketingData$(): Observable<ConsumerUserMarketingDataStoreInterfaceSnapshotOut>

  getAndPersistRetailerUserInfo$(): Observable<RetailerUserInfoStoreInterface>

  persistUserType(userType: userTypes): void

  persistShippingAddress$(args: PatchShippingAddress): Observable<any>

  choosePickUpShop$(shop: ShopManagerApiResultInterface): Observable<any>

  validateShopCode$(shopCode: string): Observable<ShopManagerInterface>

  validateAndPersistShopCode$(shopCode: string): Observable<ShopManagerInterface>

  getShippingAddresses$(): Observable<ShippingAddressStoreInterface>

  putCartHeaderAndPersist$(): Observable<ShoppingCartHeaderApiResultInterface>

  persistQty$(productId: string, qty: number): Observable<any>

  getAndPersistShippingMethods$(): Observable<ShippingMethodModel[]>

  checkShippingMethod$(): Observable<any>

  getAndPersistShippingDates$(cartId: string): Observable<any>
}

/**
 * @class
 * @desc this class is very helpfull to get or put data of root store and childrens
 */
export default class ShoppingCartDataService implements ShoppingCartDataServiceInterface {
  constructor(
    private shoppingCartApi: FacadeApiClientInterface,
    private shoppingCartStore: ShoppingCartStoreInterface,
    private shippingAddressService: ShippingAddressInterface,
    private storage: StorageWrapperInterface,
    private amplitudeEvent: AmplitudeEventInterface,
    private couponManager: CouponManagerServiceInterface
  ) {}

  /**
   * @desc get and persist user types
   */
  getAndPersistUserTypes$(): Observable<UserTypesStoreInterfaceSnapshotOut> {
    return this.shoppingCartApi.getUserTypes$().pipe(
      mergeMap((emit) => {
        datadogService.initialize(emit.id.toString())
        const result = UserTypesApiAdapter(emit)
        return of(result)
      }),
      tap((result) => {
        this.shoppingCartStore.setUserTypes(result)
      })
    )
  }

  /**
   * @desc get and persist user marketing data of consumer user
   */
  getAndPersistConsumerUserMarketingData$(): Observable<ConsumerUserMarketingDataStoreInterfaceSnapshotOut> {
    return this.shoppingCartApi.getConsumerUserMarketingData$().pipe(
      mergeMap((emit) => {
        const result = ConsumerUserMarketingDataAdapter(emit)
        return of(result)
      }),
      tap((result) => {
        this.shoppingCartStore.setConsumerUserMarketingData(result)
      })
    )
  }

  /**
   * @desc get consumer user additional information and persist in consumerUserInfo store
   */
  getAndPersistConsumerUserInfo$(): Observable<ConsumerUserInfoStoreInterfaceSnapshotOut> {
    return this.shoppingCartApi.getConsumerUserInfo$().pipe(
      mergeMap((emit) => {
        const result = ConsumerUserInfoApiAdapter(emit)
        return of(result)
      }),
      tap((result) => {
        this.shoppingCartStore.setConsumerUserInfo(result)
        // create/update userInfo cookies to show logged user in photosi.com
        setUserInfo(this.shoppingCartStore)
      })
    )
  }

  /**
   * @desc get consumer user additional information and persist in RetailerUserInfo store
   */
  getAndPersistRetailerUserInfo$(): Observable<RetailerUserInfoStoreInterfaceSnapshotOut> {
    return this.shoppingCartApi.getRetailerUserInfo$().pipe(
      mergeMap((emit) => {
        const result = RetailerUserInfoApiAdapter(emit)
        return of(result)
      }),
      tap((result) => {
        this.shoppingCartStore.setRetailerUserInfo(result)
      })
    )
  }

  /**
   * @desc return shopping cart header from api
   * @method
   */
  getCartHeader$(): Observable<ShoppingCartHeaderApiResultInterface> {
    return this.shoppingCartApi.getHeader$()
  }

  /**
   * get retailer data from cookie and persist in rootstore + side effect: patch cart api with retailer data
   */
  originIsRetailerDataResolve$(): Observable<UniqueRetailerDataCookieInteface | void> {
    const shopCode = getRetailerShopCodeFromCookie()
    if (!shopCode) return of()
    const retailerDataFromCookie: UniqueRetailerDataCookieInteface = getUniqueRetailerDataFromCookie()
    if (retailerDataFromCookie.shopCode && retailerDataFromCookie.siteDomain) {
      this.shoppingCartApi.patchUniqueRetailer$(retailerDataFromCookie).subscribe(
        () => {},
        (err: Error) => console.error('Failed to patch Retailer data of cart api', err),
        () => {}
      )
      this.shoppingCartStore.productsHeader.setRetailer(retailerDataFromCookie)
      return of(retailerDataFromCookie)
    }
    return of()
  }

  /**
   * @desc get and persist cart products
   */
  getAndPersistProducts$(): Observable<any> {
    return this.shoppingCartApi.getProductsHeader$().pipe(mergeMap((result: any) => this.productHeaderResolve$(result)))
  }

  /**
   * @desc get and persist cart header data
   */
  getAndPersistHeader$(): Observable<any> {
    return this.getCartHeader$().pipe(mergeMap((result: any) => this.cartHeaderResolve$(result)))
  }

  /**
   * @desc get and persist current shipping Addresses
   */
  getShippingAddresses$(): Observable<ShippingAddressStoreInterface> {
    return this.shoppingCartApi.getShippingAddresses$().pipe(
      mergeMap((result: any) => {
        this.shippingAddressService.setAddresses(result)
        return of(result)
      })
    )
  }

  /**
   * @desc get headers and product from api and set in store
   * @method
   */
  getAndPersistHeadersAndProducts$(): Observable<ProductsHeaderStoreInterface> {
    return this.getAndPersistProducts$().pipe(
      mergeMap((emit) => {
        //force default software code in case of iplabsflow
        if (emit.isIpLabsFlow === true) {
          this.storage.persist(SOFTWARECODE_KEY, SOFTWARE_CODE)
          this.shoppingCartApi.resetCustomRequestHeader()
        } else {
          this.storage.persist(SOFTWARECODE_KEY, SOFTWARE_CODE_SERVICES)
          this.shoppingCartApi.resetCustomRequestHeader()
        }

        // Set guid in global api client service
        this.shoppingCartApi.setGuid(emit.id)

        // get headers infomation and persist in store (guid is required)
        const cartHeader$ = this.getAndPersistHeader$()

        //get shipping address and persist in store
        const shippingAddresses$ = this.shoppingCartApi.getShippingAddresses$().pipe(
          mergeMap((result: any) => {
            this.shippingAddressService.setAddresses(result)
            return of(result)
          })
        )

        // RESOLVE OBSERVABLE AND RETURN REDUCED OBJECT
        return forkJoin([shippingAddresses$, cartHeader$]).pipe(
          reduce((acc, value) => {
            return Object.assign(acc, value)
          }, {})
        )
      })
    )
  }

  /**
   * @desc manage process to create header in shopping cart store
   * @param result
   */
  private cartHeaderResolve$(result: ShoppingCartHeaderApiResultInterface): Observable<ShoppingCartHeaderApiResultInterface> {
    if (result instanceof Error) return of(result)
    const header: ShoppingCartHeaderStoreInterfaceSnapshotOut = HeaderStoreAdapter(result)
    this.shoppingCartStore.setHeader(header)

    const shippingMethod: ShippingMethodStoreInterfaceSnapshotOut = {
      code: header.shippingType,
      cost: header.vatIncludedShippingCost,
      currencyCode: header.currencyCode,
      description: null,
      formattedCost: header.formattedVatIncludedShippingCost,
      name: null,
      disabled: true,
      reason: null
    }

    this.shoppingCartStore.setShippingMethod(shippingMethod)

    return of(result)
  }

  /**
   * @desc manage process to create product header in shopping cart store
   * @param result
   */
  private productHeaderResolve$(result: ProductsHeaderApiResultInterface): Observable<ProductsHeaderStoreInterface> {
    if (result instanceof Error) return of(result)
    /** ProductsHeaderApiResultInterface | Error */
    // SET PRODUCTS IN SHOPPING CART STORE
    this.shoppingCartStore.setProducts(ProductsStoreAdapter(result.cartItems))

    // SET HEADER OF ORDERS IN SHOPPING CART STORE
    this.shoppingCartStore.setProductsHeader(ProductsHeaderAdapter(result))

    this.shoppingCartStore.setStatus(ShoppingCartStoreAcceptedStatusList.ready)

    return of(result)
  }

  /**
   * @desc get and persist order full data (cart ≠ order)
   */
  getAndPersistOrder$(): Observable<OrderStoreInterfaceSnapshotOut> {
    return this.shoppingCartApi.getOrderState$().pipe(
      map((emit: OrderApiResult): OrderStoreInterfaceSnapshotOut => OrderStoreAdapter(emit)),
      mergeMap((emit: OrderStoreInterfaceSnapshotOut) => {
        this.shoppingCartStore.setOrder(emit)
        return of(emit)
      })
    )
  }

  /**
   * @desc put cart header to api
   * @method
   */
  putCartHeader$(): Observable<ShoppingCartHeaderApiResultInterface> {
    const header: ShoppingCartHeaderApiResultInterface = HeaderApiAdapter(getSnapshot(this.shoppingCartStore.header))
    return this.shoppingCartApi.putHeader$(header).pipe(
      mergeMap((putResponse) => {
        // get shipping dates and persist in store
        this.getAndPersistShippingDates$(header.id).toPromise()

        // try to apply coupon back if removed
        if (putResponse.removedCouponCode) {
          return this.couponManager.applyCoupon$(putResponse.removedCouponCode)
        }
        return of(putResponse)
      })
    )
  }

  /**
   * @desc put and persist in local store cart header
   */
  putCartHeaderAndPersist$(): Observable<ShoppingCartHeaderApiResultInterface> {
    return this.putCartHeader$().pipe(
      mergeMap((header: ShoppingCartHeaderApiResultInterface) => {
        this.shoppingCartStore.setHeader(HeaderStoreAdapter(header))
        return of(header)
      })
    )
  }

  /**
   * @desc patch cart with current shop code and retailerid
   */
  patchCurrentShopDataForPickUpOrder$(): Observable<any> {
    const shippingMethod: ShippingMethodStoreInterfaceSnapshotOut = getSnapshot(this.shoppingCartStore.shippingMethod)
    const shopForPickUp: ShopForPickUpInterfaceSnapshotOut = getSnapshot(this.shoppingCartStore.shopForPickUp)
    if (shippingMethod.code === SHIPPING_METHOD_PICKUP) {
      return this.shoppingCartApi.patchShopCode$(shopForPickUp.shopCode, shopForPickUp.iplabsRetailerId)
    } else {
      return of(null)
    }
  }

  /**
   * @desc send header to api with shipping data,
   *       update available shipping methods based on address,
   *       check choosen shipping method is still valid
   * @param address
   */
  persistChoosedShippingAddress$(
    shippingType: acceptedShippingMethodNames,
    shopCode?: string,
    paymentType?: acceptedPaymentType
  ): Observable<any> {
    const currentHeader: ShoppingCartHeaderStoreInterfaceSnapshotOut = getSnapshot(this.shoppingCartStore.header)
    const newAddress: ShoppingCartHeaderStoreInterfaceSnapshotOut = Object.assign({}, currentHeader)

    switch (shippingType) {
      case SHIPPING_METHOD_EXPRESS:
      case SHIPPING_METHOD_MAILORDER:
        const address = this.shoppingCartStore.getSelectedShippingAddress()
        Object.assign(newAddress, {
          billingAddressId: address.id,
          shippingAddressId: address.id
        })

        const mailOrderShopCode = this.shoppingCartStore.productsHeader.distributor.mailOrderShopCode
        if (mailOrderShopCode) {
          Object.assign(newAddress, {
            shopCode: mailOrderShopCode
          })
        }

        break
      case SHIPPING_METHOD_PICKUP:
        const shop: ShopForPickUpInterfaceSnapshotOut = getSnapshot(this.shoppingCartStore.shopForPickUp)
        Object.assign(newAddress, {
          shippingAddressArea: shop.area,
          shippingAddressCity: shop.city,
          shippingAddressCompanyName: shop.name,
          shippingAddressCountry: shop.country,
          shippingAddressEmail: shop.email,
          shippingAddressStreet: shop.street,
          shippingAddressZipCode: shop.zipCode
        })
        switch (this.shoppingCartStore.userType) {
          case RETAILER_USER: {
            const customerInfo = this.shoppingCartStore.shippingDataForRetailer
            Object.assign(newAddress, {
              shippingAddressFirstName: customerInfo.firstname,
              shippingAddressLastName: customerInfo.lastname,
              shippingAddressPhone: customerInfo.phone
            })
            break
          }
          case CONSUMER_USER: {
            const consumerUserInfo: ConsumerUserInfoStoreInterfaceSnapshotIn = getSnapshot(this.shoppingCartStore.consumerUserInfo)
            Object.assign(newAddress, {
              shippingAddressFirstName: consumerUserInfo.name,
              shippingAddressLastName: consumerUserInfo.surname,
              shippingAddressPhone: consumerUserInfo.phoneNumber
            })
            break
          }
        }
        break
      default:
        return throwError('Errore grave, impossibile persistere i dati di spedizione')
    }

    if (shippingType) {
      Object.assign(newAddress, { shippingType: shippingType })
    }

    if (shopCode) {
      Object.assign(newAddress, { shopCode: shopCode })
    }

    if (paymentType) {
      Object.assign(newAddress, { paymentType: paymentType })
    }

    switch (shippingType) {
      case SHIPPING_METHOD_EXPRESS:
      case SHIPPING_METHOD_MAILORDER:
        this.shoppingCartStore.setHeaderMailOrder(newAddress)
        break
      case SHIPPING_METHOD_PICKUP:
        this.shoppingCartStore.setHeaderPickUpStore(newAddress)
        break
    }

    return concat(
      this.putCartHeaderAndPersist$(),
      this.getAndPersistOrder$(),
      this.getAndPersistShippingMethods$(),
      this.checkShippingMethod$()
    )
  }

  // NORMALLY USED ONLY FOR DEVELOPMENT
  persistUserType(userType: userTypes): void {
    let getUser$: Observable<any>
    switch (userType) {
      case RETAILER_USER:
        getUser$ = this.getAndPersistRetailerUserInfo$()
        break
      default:
      case CONSUMER_USER:
        getUser$ = this.getAndPersistConsumerUserInfo$()
        break
    }

    getUser$.subscribe(() => {
      this.shoppingCartStore.setUserType(userType)
    })
  }

  /**
   * @desc Check if current shipping address is compatible with available ones,
   * and updates it accordingly
   */
  checkShippingMethod$() {
    // The whole observable chain is useless here, and is kept only for consistency

    return of(null).pipe(
      map(() => {
        // Since MailOrder may have been forced, check if it's really available
        // and if not, try to fallback to express
        const currentShippingMethod = this.shoppingCartStore.shippingMethod.code
        if (currentShippingMethod === SHIPPING_METHOD_PICKUP) {
          // No ckeck needed for pickup
          return of()
        }

        const availableShippingMethods = this.shoppingCartStore.getShippingMethods()
        const mailOrder = availableShippingMethods.filter((sm: any) => sm.code === SHIPPING_METHOD_MAILORDER)[0]
        const express = availableShippingMethods.filter((sm: any) => sm.code === SHIPPING_METHOD_EXPRESS)[0]
        const newShippingMethod =
          currentShippingMethod === mailOrder?.code || currentShippingMethod === express?.code
            ? this.shoppingCartStore.shippingMethod
            : mailOrder || express

        if (newShippingMethod.code === this.shoppingCartStore.header.shippingType) {
          // Nothing more to do here, selected shipping method is compatible with address
          return of()
        }

        // Set new shipping method locally
        setShippingMethod(newShippingMethod, this.shoppingCartStore)
        this.amplitudeEvent.deliverytypesAutoSelect()
        // Save it on server
        return this.persistChoosedShippingAddress$(newShippingMethod.code)
      })
    )
  }

  /**
   * @desc persist shipping address to db
   */
  persistShippingAddress$({ shippingType, shopCode, paymentType, firstName, lastName, phone }: PatchShippingAddress): Observable<any> {
    return this.persistChoosedShippingAddress$(shippingType, shopCode, paymentType)
  }

  /**
   * @desc event to choose shop for pickup shipping
   * @param shop
   */
  choosePickUpShop$ = (shop: ShopManagerApiResultInterface): Observable<any> => {
    this.shoppingCartStore.setShopForPickUp(ShopAdapterToStore(shop))

    let userTypeKey: string = 'consumerUserInfo'
    switch (this.shoppingCartStore.userType) {
      case RETAILER_USER:
        userTypeKey = 'retailerUserInfo'
        break
      case CONSUMER_USER:
        userTypeKey = 'consumerUserInfo'
        break
    }

    return this.persistShippingAddress$({
      shopCode: shop.shopCode,
      firstName: this.shoppingCartStore[userTypeKey].name,
      lastName: this.shoppingCartStore[userTypeKey].surname,
      phone: this.shoppingCartStore[userTypeKey].phoneNumber,
      shippingType: SHIPPING_METHOD_PICKUP
    })
  }

  validateShopCode$(shopCode: string): Observable<ShopManagerInterface> {
    return new Observable<ShopManagerInterface>((observer: Observer<ShopManagerInterface>) => {
      this.shoppingCartApi.getShopDetails$(shopCode).subscribe(
        (emit: ShopManagerApiResultInterface[]) => {
          observer.next(ShopManagerApiAdapter(emit[0]))
          observer.complete()
        },
        (err: Error) => {
          observer.error(err)
        },
        () => {
          observer.complete()
        }
      )
    })
  }

  validateAndPersistShopCode$(shopCode: string): Observable<ShopManagerInterface> {
    return this.validateShopCode$(shopCode).pipe(
      tap((shop: ShopManagerApiResultInterface) => {
        this.shoppingCartStore.setShopForPickUp(ShopAdapterToStore(shop))
      }),
      mergeMap((shop: ShopManagerApiResultInterface) => {
        return of(ShopManagerApiAdapter(shop))
      })
    )
  }

  persistQty$(productId: string, qty: number): Observable<any> {
    return concat(
      this.shoppingCartApi.updateProductQty$(productId, qty),
      this.getAndPersistProducts$(),
      this.getAndPersistHeader$(),
      of(qty)
    )
  }

  getShippingMethodCollection$(): Observable<ShippingMethodModel[]> {
    return this.shoppingCartApi.getShippingMethod$().pipe(
      flatMap((shippingMethodListFromApi: ShippingMethodApiResult[]) => {
        const shippingMethodList: ShippingMethodModel[] = shippingMethodListFromApi.filter((apiShippingMethod: ShippingMethodApiResult) => {
          const method: ShippingMethodModel = ShippingMethodAdapter(apiShippingMethod)

          const distributorShippingMethod = this.shoppingCartStore.productsHeader.distributor.getShippintMethod()
          if (distributorShippingMethod.includes(method.code.toLowerCase())) return method

          return null
        })
        return of(shippingMethodList)
      })
    )
  }

  getAndPersistShippingMethods$(): Observable<ShippingMethodModel[]> {
    return this.getShippingMethodCollection$().pipe(
      tap((shippingMethodList: ShippingMethodModel[]) => {
        this.shoppingCartStore.setShippingMethods(shippingMethodList)
      })
    )
  }

  getAndPersistShippingDates$(cartId: string) {
    return this.shoppingCartApi.getShippingDate$(cartId).pipe(
      tap((shippingDates) => {
        this.shoppingCartStore.setShippingDates(shippingDates)
      })
    )
  }
}
